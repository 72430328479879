// Usar o nome correto da variável de ambiente
const API_URL = process.env.REACT_APP_API_BASE_URL || 'https://one00aimktools-backend.onrender.com';

export const saveSubscriber = async (formData) => {
  try {
    console.log('Attempting to fetch from:', `${API_URL}/api/subscribe`);
    console.log('Form data:', JSON.stringify(formData));
    
    const response = await fetch(`${API_URL}/api/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include', // Adiciona esta linha para incluir cookies nas requisições cross-origin
      mode: 'cors' // Explicita o modo CORS
    });

    console.log('Response status:', response.status);
    console.log('Response ok:', response.ok);

    let data;
    try {
      data = await response.json();
    } catch (e) {
      console.error('Falha ao parsear a resposta JSON:', e);
      const textResponse = await response.text();
      console.error('Resposta bruta:', textResponse);
      throw new Error('Resposta inválida do servidor');
    }

    if (!response.ok) {
      console.log('Error response:', data);
      throw new Error(data.error || 'Erro ao salvar os dados');
    }

    console.log('Success response:', data);
    return data;
  } catch (error) {
    console.error('Erro detalhado ao salvar inscrição:', error);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('Possível problema de rede ou CORS');
    }
    throw error;
  }
};