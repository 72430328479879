import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Input,
  Select,
  Text,
  Link,
  VStack,
  HStack,
  Flex,
} from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const Apps = () => {
  return (
    <>
      <SEO 
        title="Aplicativos de IA para Marketing | Explore Nossa Lista Curada"
        description="Acesse nossa lista completa de aplicativos de IA para marketing. Encontre as melhores ferramentas para copywriting, análise de dados, automação e muito mais."
        keywords="ferramentas de IA, apps de marketing, automação, copywriting, análise de dados"
        canonicalUrl="https://aimktapps.com/ferramentas"
      />
      {/* Resto do conteúdo da página Tools */}
    </>
  );
};

const ITEMS_PER_PAGE = 9;

const Tools = ({ toolsData = [], categories = [] }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTools, setFilteredTools] = useState([]);
  const [displayedTools, setDisplayedTools] = useState([]);
  const [page, setPage] = useState(1);

  const observer = useRef();
  const lastToolElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && displayedTools.length < filteredTools.length) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [displayedTools, filteredTools]);

  useEffect(() => {
    const filtered = toolsData.filter(tool => {
      const matchesCategory = !selectedCategory || tool.categories.includes(selectedCategory);
      const matchesSearch = tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            tool.info.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesCategory && matchesSearch;
    });
    setFilteredTools(filtered);
    setPage(1);
  }, [toolsData, selectedCategory, searchTerm]);

  useEffect(() => {
    setDisplayedTools(filteredTools.slice(0, page * ITEMS_PER_PAGE));
  }, [filteredTools, page]);

  return (
    <Box bg="#CFDEF4" minHeight="100vh">
      <Header />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" fontFamily="Playfair Display, serif" color="#333" textAlign="center">
            AI Marketing Apps
          </Heading>
          <Text textAlign="center" fontSize="lg" fontFamily="Lato, sans-serif">
            Seu arquivo de aplicativos de AI para marketing.
          </Text>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Input 
              placeholder="Busca" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              bg="white"
              width="300px"
            />
            <Select 
              placeholder="Tipo de aplicativo" 
              bg="white"
              width="200px"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categories.map(cat => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </Select>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {displayedTools.map((tool, index) => (
              <Box 
                key={tool.name} 
                borderRadius="20px" 
                p={6} 
                bg="white" 
                boxShadow="md"
                ref={index === displayedTools.length - 1 ? lastToolElementRef : null}
              >
                <VStack align="start" spacing={2}>
                  <Heading as="h3" size="md" fontFamily="Playfair Display, serif" fontSize="18px" >{tool.name}</Heading>
                  <Link href={tool.website} isExternal color="#4A5568" fontSize="14px" fontWeight="bold" textDecoration="underline" fontFamily="Lato, sans-serif">
                    {tool.website}
                  </Link>
                  <Text fontSize="14px" fontFamily="Lato, sans-serif">{tool.info}</Text>
                  <HStack>
                    {tool.categories.map(category => (
                      <Text key={category} color="#307BEC" fontSize="12px" fontWeight="bold">{category}</Text>
                    ))}
                  </HStack>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
      <Footer />
    </Box>
  );
};

export default Tools;