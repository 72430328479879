const toolsData = [
  {
    name: "Writesonic",
    website: "https://writesonic.com",
    info: "Diga adeus ao bloqueio do escritor. Com as ferramentas de escrita alimentadas por IA do Writesonic, você pode gerar anúncios, blogs, páginas de destino, descrições de produtos, ideias e muito mais de alto desempenho em segundos.",
    categories: ["Assistente com IA"],
    aiTools: true
  },
  {
    name: "Grain",
    website: "https://grain.co",
    info: "Grave, recorte e compartilhe vídeo do Zoom em tempo real. Uma ferramenta essencial para equipes modernas.",
    categories: ["Gravação de Conversas"],
    aiTools: true
  },
  {
    name: "Levity",
    website: "https://levity.ai",
    info: "Crie sua própria IA para documentos, imagens ou texto que tira tarefas repetitivas diárias dos seus ombros para que sua equipe possa alcançar o próximo nível de produtividade.",
    categories: ["Assistente com IA", "Integrações e Fluxos de Trabalho"],
    aiTools: true
  },
  {
    name: "Akkio",
    website: "https://akkio.com",
    info: "Vá dos dados à IA em 10 minutos — sem código ou habilidades de ciência de dados necessárias.",
    categories: ["Dados"],
    aiTools: true
  },
  {
    name: "Folk",
    website: "https://folk.app",
    info: "Folk é uma ferramenta colaborativa para centralizar, organizar e ativar contatos.",
    categories: ["CRM"],
    aiTools: true
  },
  {
    name: "Quicklines",
    website: "https://quicklines.ai",
    info: "Aumente suas taxas de abertura, resposta e agendamento de chamadas com nosso gerador de primeiras linhas alimentado por IA e pesquisa profunda de dados sociais.",
    categories: ["Conteúdo personalizado"],
    aiTools: true
  },
  {
    name: "Craftly",
    website: "https://craftly.ai",
    info: "Craftly é seu assistente de redação alimentado por IA que gera conteúdo original do jeito que você gosta.",
    categories: ["Assistente com IA"],
    aiTools: true
  },
  {
    name: "Writecream",
    website: "https://writecream.com",
    info: "Crie conteúdo de marketing e emails de vendas em segundos, a uma fração do custo.",
    categories: ["Conteúdo personalizado"],
    aiTools: false
  },
  {
    name: "Vimeo",
    website: "https://vimeo.com/pt-br/",
    info: "Traduza qualquer vídeo para dezenas de idiomas usando nossa nova ferramenta de tradução com IA.",
    categories: ["Tradução de vídeo"],
    aiTools: true
  },
  {
    name: "Datagran",
    website: "https://datagran.io",
    info: "A Datagran executa automaticamente seu modelo de dados nas informações e move a saída entre seus aplicativos de negócios sem esforço, para que você possa se concentrar no que é importante.",
    categories: ["Dados"],
    aiTools: false
  },
  {
    name: "HeyGen",
    website: "https://app.heygen.com/",
    info: "Personalize seu alcance com esta IA de ação rápida que cria vídeos envolventes em minutos. Transformando qualquer texto em um vídeo com apresentador.",
    categories: ["Criação de Vídeos"],
    aiTools: true
  },
  {
    name: "Midjourney",
    website: "https://www.midjourney.com/home",
    info: "Uma das melhores AIs para criação de Imagens para ações de marketing",
    categories: ["Criação de imagens"],
    aiTools: true
  },
  {
    name: "ColdList",
    website: "https://coldlist.io",
    info: "Obtenha 4 vezes mais respostas aos seus cold mails com IA {IceBreakers}.",
    categories: ["Conteúdo personalizado"],
    aiTools: true
  },
  {
    name: "Reach",
    website: "https://magicreach.ai",
    info: "A maneira mais rápida de personalizar emails.",
    categories: ["Conteúdo personalizado", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Humantic AI",
    website: "https://humantic.ai",
    info: "Humantic AI é uma plataforma de Inteligência do Comprador para equipes de receita.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Drafter AI",
    website: "https://drafter.ai",
    info: "Construa ferramentas alimentadas por IA em meses horas.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "Luna",
    website: "https://getluna.ai",
    info: "Personalize cold mails instantaneamente com Luna.",
    categories: ["Conteúdo personalizado", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "SellScale",
    website: "https://sellscale.com",
    info: "Superalimente seu outbound com IA.",
    categories: ["Conteúdo personalizado", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Opnbx",
    website: "https://opnbx.ai",
    info: "Envie um cold email personalizado em 30 segundos.",
    categories: ["Conteúdo personalizado", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Cargo",
    website: "https://getcargo.io",
    info: "O sistema de engajamento em cima do seu data warehouse.",
    categories: ["Produtividade", "Integrações e Fluxos de Trabalho"],
    aiTools: true
  },
  {
    name: "Briskine",
    website: "https://briskine.com",
    info: "Escreva emails mais rápido.",
    categories: ["Produtividade", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "OneShot",
    website: "https://oneshot.ai",
    info: "Pessoa certa. Mensagem certa. Momento certo.",
    categories: ["Produtividade", "Alcance por Email", "Assistente de Email com IA", "SDR com IA"],
    aiTools: true
  },
  {
    name: "Beautiful.ai",
    website: "https://www.beautiful.ai/",
    info: "Apresentação para negócios feita por IA",
    categories: ["Apresentações"],
    aiTools: true
  },
  {
    name: "Bardeen",
    website: "https://bardeen.ai",
    info: "Integre e automatize seus aplicativos favoritos com nossa plataforma de automação de IA.",
    categories: ["Integrações e Fluxos de Trabalho"],
    aiTools: true
  },
  {
    name: "Ralph AI",
    website: "https://ralph.ai",
    info: "Por que perder tempo escrevendo conteúdo de vendas? Nossa IA faz melhor em 10 segundos.",
    categories: ["Assistente com IA"],
    aiTools: true
  },
  {
    name: "Humanic",
    website: "https://humanic.ai",
    info: "Descubra seu Pipeline Oculto com essa IA de prospecção.",
    categories: ["Email em massa"],
    aiTools: true
  },
  {
    name: "tl;dv",
    website: "https://tldv.io",
    info: "Nós fazemos suas anotações de reunião. Você conduz o show.",
    categories: ["Inteligência de Conversação", "Gravação de Conversas"],
    aiTools: true
  },
  {
    name: "SheetAI",
    website: "https://sheetai.app",
    info: "Descreva o que você quer e veja a mágica acontecer em sua planilha.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "Sonnet",
    website: "https://sonnetai.com",
    info: "Sonnet grava chamadas, toma notas e mantém o controle de itens de ação — para que você possa se concentrar em suas reuniões.",
    categories: ["Gravação de Conversas"],
    aiTools: true
  },
  {
    name: "Coldreach",
    website: "https://coldreach.ai",
    info: "Econtre leads que realmente precisam de você.",
    categories: ["Assistente de Email com IA", "Gatilhos e Inteligência de Vendas"],
    aiTools: true
  },
  {
    name: "Firefly",
    website: "https://firefly.adobe.com/",
    info: "Criação de imagens com IA da empresa Adobe.",
    categories: ["Criação de imagens"],
    aiTools: true
  },
  {
    name: "Canva",
    website: "https://www.canva.com/",
    info: "O estúdio mágico da Canva reune diversas ferramentas para criação usando AI",
    categories: ["Criação de imagens", "Criação de vídeos",],
    aiTools: true
  },
  {
    name: "Supermail",
    website: "https://superemail.ai",
    info: "Assistente de email alimentado por IA que facilita a elaboração de emails profissionais e eficazes.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Superflows",
    website: "https://superflows.ai",
    info: "Tenha um AI expert em seus produtos na sua empresa. Encante seus clientes.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Gyaan",
    website: "https://gyaan.ai",
    info: "O espaço de trabalho definitivo para equipes de receita aumentarem as taxas de ganho e fecharem negócios maiores em menos tempo.",
    categories: ["Integrações e Fluxos de Trabalho"],
    aiTools: true
  },
  {
    name: "eCold.ai",
    website: "https://ecold.ai",
    info: "Obtenha centenas de linhas personalizadas em segundos.",
    categories: ["Conteúdo personalizado"],
    aiTools: true
  },
  {
    name: "Airtable",
    website: "https://airtable.com",
    info: "Acelere o trabalho e desbloqueie o potencial com aplicativos poderosos que conectam seus dados, fluxos de trabalho e equipes.",
    categories: ["Integrações e Fluxos de Trabalho"],
    aiTools: true
  },
  {
    name: "Elementor AI",
    website: "https://elementor.com/",
    info: "Revolucione a criação da Web para todos os seus sites Elementor com as novas soluções de IA do Elementor.",
    categories: ["Criação de sites"],
    aiTools: true
  },
  {
    name: "Rattle",
    website: "https://gorattle.com",
    info: "Sua equipe de receita operando sem falhas com o poder, a velocidade e a consistência da Automação de Processos.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "Numerous.ai",
    website: "https://numerous.ai",
    info: "Extraia texto, categorize, gere fórmulas e use o ChatGPT diretamente dentro da sua planilha.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "GPT for Work",
    website: "https://gptforwork.com",
    info: "Use ChatGPT e Claude em Planilhas, Documentos e Excel.",
    categories: ["GPT"],
    aiTools: true
  },
  {
    name: "Typewise",
    website: "https://typewise.app",
    info: "Triplique sua produtividade de atendimento ao cliente e vendas com nosso Assistente de Comunicação com IA.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Rytr",
    website: "https://rytr.me",
    info: "Uma maneira melhor e 10 vezes mais rápida de escrever emails.",
    categories: ["Assistente com IA", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Type",
    website: "https://type.ai",
    info: "O editor de documentos alimentado por IA mais poderoso do mundo, que ajuda você a escrever notavelmente rápido.",
    categories: ["Assistente com IA"],
    aiTools: true
  },
  {
    name: "Sembly AI",
    website: "https://sembly.ai",
    info: "Sembly transcreve, toma notas de reuniões e gera insights para suas reuniões profissionais.",
    categories: ["Gravação de Conversas"],
    aiTools: true
  },
  {
    name: "Robin",
    website: "https://hellorobin.ai",
    info: "Seu novo agente de vendas outbound.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Argil",
    website: "https://www.argil.ai/",
    info: "Obtenha um vídeo perfeito para a mídia social de você ou de um avatar genérico em 2 minutos",
    categories: ["Criação de Vídeos"],
    aiTools: true
  },
  {
    name: "Twain",
    website: "https://twain.ai",
    info: "Escreva emails que obtêm respostas.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Followr",
    website: "https://followr.ai/",
    info: "Ferramenta avançada que aproveita os recursos de IA para ajudar as empresas a otimizar sua presença nas mídias sociais.",
    categories: ["Social media"],
    aiTools: true
  },
  {
    name: "Replix AI",
    website: "https://replix.ai",
    info: "Eleve sua criação de conteúdo com Replix AI.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Kalendar AI",
    website: "https://kalendar.ai",
    info: "Agende novas receitas no piloto automático com IA.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Selma",
    website: "https://selma.ai",
    info: "Diga adeus aos emails de vendas manuais e sequências. Conheça Selma.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Magical",
    website: "https://getmagical.com",
    info: "Magical é um aplicativo de produtividade que usa IA para acelerar tarefas repetitivas enquanto você trabalha.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "Salesforge",
    website: "https://salesforge.ai",
    info: "Sequenciamento programático de cold email aproveitando IA para criar emails únicos para aumentar 2-3x a taxa de resposta em escala.",
    categories: ["Assistente de Email com IA", "Alcance por Email"],
    aiTools: true
  },
  {
    name: "Wavtool",
    website: "https://wavtool.com/",
    info: "Estação de trabalho de áudio digital (DAW) no navegador com um assistente de IA que ajuda a criar música e áudio de alta qualidade.",
    categories: ["Áudio studio"],
    aiTools: true
  },
  {
    name: "Spell",
    website: "https://spell.so",
    info: "IA que trabalha para você.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "AnyBiz",
    website: "https://anybiz.io",
    info: "Construa o funcionário de próxima geração movido a IA que gera novos clientes para seu negócio B2B.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Fiber",
    website: "https://fiber.ai",
    info: "Impulsionamos um crescimento de receita mais rápido para empresas usando automações de marketing alimentadas por IA.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "VistaSocial",
    website: "https://vistasocial.com/",
    info: "Vista Social is a modern social media management tool that aims to help you exceed your goals.",
    categories: ["Social media"],
    aiTools: true
  },
  {
    name: "FirstQuadrant",
    website: "https://firstquadrant.ai",
    info: "Vendas outbound B2B infinitamente escaláveis com IA.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "AiSDR",
    website: "https://aisdr.com",
    info: "Contrate seu primeiro SDR com IA.",
    categories: ["Assistente de Email com IA", "SDR com IA"],
    aiTools: true
  },
  {
    name: "Noki",
    website: "https://noki.so",
    info: "Agende automaticamente reuniões qualificadas com prospecção alimentada por IA.",
    categories: ["Alcance por Email", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Noty.ai",
    website: "https://noty.ai",
    info: "Economize 2 horas diárias em notas de reunião, acompanhamentos e listas de tarefas.",
    categories: ["Gravação de Conversas"],
    aiTools: true
  },
  {
    name: "Adobe Lightroom",
    website: "https://www.adobe.com/products/photoshop-lightroom.html",
    info: "Mantenha-se no momento com a IA generativa no Lightroom e remova sem esforço elementos indesejados de suas fotos com um único clique.",
    categories: ["Assistente com IA"],
    aiTools: true
  },
  {
    name: "ExemplaryAI",
    website: "https://exemplary.ai/",
    info: "Transforme vídeos longos em clipes curtos e crie resumos e muito mais com apenas um clique.",
    categories: ["Criação de Vídeos"],
    aiTools: true
  },
  {
    name: "SDR GPT",
    website: "https://sdr-gpt.com",
    info: "Alcance personalizado, no piloto automático.",
    categories: ["Assistente de Email com IA", "Conteúdo personalizado"],
    aiTools: true
  },
  {
    name: "Wordtune",
    website: "https://www.wordtune.com",
    info: "Uma plataforma GenAI completa para ajudar você a aumentar a produtividade e escrever com confiança.",
    categories: ["Assistente de Email com IA", "Produtividade"],
    aiTools: true
  },
  {
    name: "Outboundly",
    website: "https://outboundly.app",
    info: "Gere instantaneamente emails altamente personalizados com base no site do seu prospect, posts de blog, perfis de mídia social e muito mais! Obtenha taxas de resposta 6 vezes maiores.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "ezsales",
    website: "https://ezsales.ai",
    info: "Nossa IA escreve cold mails hiper-personalizados em segundos, melhor do que qualquer humano.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "B2B Rocket",
    website: "https://b2brocket.ai",
    info: "Potencialize suas vendas e agende novas receitas com IA.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Meetz",
    website: "https://meetz.ai",
    info: "Alcance totalmente personalizado e automatizado com um agendador de IA para marcar reuniões para você.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Telescope",
    website: "https://trytelescope.ai",
    info: "Descubra novos prospectos a cada deslize.",
    categories: ["Geração de Leads"],
    aiTools: true
  },
  {
    name: "Sidenote",
    website: "https://sidenote.ai",
    info: "Sidenote é uma extensão do Chrome que transforma suas notas em itens de ação e, em seguida, em rascunhos de emails, convites de calendário e problemas do Jira.",
    categories: ["Gravação de Conversas"],
    aiTools: true
  },
  {
    name: "eCold.ai",
    website: "https://pipegen.ai",
    info: "Personalize cada cold mail e-mail com IA",
    categories: ["Engajamento de Vendas"],
    aiTools: true
  },
  {
    name: "Salesfinity",
    website: "https://salesfinity.co",
    info: "Discador paralelo alimentado por IA para equipes de vendas B2B.",
    categories: ["Discador Paralelo"],
    aiTools: true
  },
  {
    name: "Persana AI",
    website: "https://persana.ai",
    info: "Potencialize suas operações de vendas com automações de IA.",
    categories: ["Prospecção Sem Código"],
    aiTools: true
  },
  {
    name: "Valley",
    website: "https://joinvalley.co",
    info: "Agende chamadas de vendas enquanto você faz literalmente qualquer outra coisa.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Trigify",
    website: "https://trigify.io",
    info: "Abandone a pesquisa manual, as horas gastas vasculhando o LinkedIn ou lendo quadros de empregos. Contrate um SDR Digital de IA para executar o desenvolvimento de seus negócios.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Cal",
    website: "https://cal.com",
    info: "Conheça o Cal.com, o agendador que malabariza eventos para todos. Concentre-se em se reunir, não em marcar reuniões.",
    categories: ["Agendamento de Reuniões"],
    aiTools: true
  },
  {
    name: "Superhuman",
    website: "https://superhuman.com",
    info: "A experiência de email mais rápida já criada.",
    categories: ["Produtividade"],
    aiTools: true
  },
  {
    name: "Sail",
    website: "https://gosail.app",
    info: "Encontre e feche negócios mais rápido que seus concorrentes com IA que realmente funciona.",
    categories: ["Geração de Leads"],
    aiTools: true
  },
  {
    name: "Letter AI",
    website: "https://letter.ai",
    info: "Equipe suas equipes de receita com tudo o que elas precisam para fechar negócios - desde treinamento e coaching até materiais de apoio e ajuda em tempo real - em uma plataforma unificada com IA no núcleo.",
    categories: ["Capacitação de Vendas", "Produtividade"],
    aiTools: true
  },
  {
    name: "ConvoZen.AI",
    website: "https://convozen.ai",
    info: "Aproveite insights e acionáveis orientados por IA de suas conversas com clientes em todos os canais - Chamadas, Chats e Reuniões para impulsionar a eficiência do seu negócio.",
    categories: ["Gravação de Conversas", "Inteligência de Conversação"],
    aiTools: true
  },
  {
    name: "aomni",
    website: "https://aomni.com",
    info: "Aomni usa automação inteligente para ajudar vendedores B2B a construir melhores relacionamentos com compradores e economizar tempo em trabalho de preparação, ao longo do ciclo de vendas.",
    categories: ["Produtividade", "Capacitação de Vendas"],
    aiTools: true
  },
  {
    name: "Air",
    website: "https://air.ai",
    info: "Apresentando a primeira IA do mundo que pode ter chamadas telefônicas completas de 10-40 minutos que soam como um humano REAL.",
    categories: ["Inteligência de Conversação", "SDR com IA"],
    aiTools: true
  },
  {
    name: "Smartlead",
    website: "https://smartlead.ai",
    info: "Escale seu alcance com confiança com caixas de correio ilimitadas, aquecimentos ilimitados, uma infraestrutura multicanal ilimitada.",
    categories: ["Alcance por Email"],
    aiTools: true
  },
  {
    name: "SignalSpot",
    website: "https://signalspot.io",
    info: "SignalSpot é uma plataforma de prospecção construída para ajudar equipes de vendas SaaS a agendar mais reuniões através de mensagens hiper personalizadas.",
    categories: ["Gatilhos e Inteligência de Vendas", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Pitch Patterns",
    website: "https://pitchpatterns.com",
    info: "Pitch Patterns permite fazer Pitch de vendas mais rápido com automatização de IA com aprendizado em cada chamada.",
    categories: ["Treinamento de Vendas"],
    aiTools: true
  },
  {
    name: "Bloks",
    website: "https://bloks.app",
    info: "Bloks reúne todas as informações que você precisa para vir preparado para reuniões com zero esforço.",
    categories: ["Assistente com IA", "Construção de Rapport"],
    aiTools: true
  },
  {
    name: "Clay",
    website: "https://clay.earth",
    info: "Finalmente, gerencie todos os seus relacionamentos pessoais e profissionais.",
    categories: ["CRM"],
    aiTools: true
  },
  {
    name: "SuperCold",
    website: "https://getsupercold.com",
    info: "Pesquise nosso banco de dados ou faça upload de sua lista de contatos, e deixe o SuperCold personalizar cada alcance para o máximo impacto.",
    categories: ["Assistente de Email com IA", "Alcance por Email", "Engajamento de Vendas"],
    aiTools: true
  },
  {
    name: "Salee AI",
    website: "https://salee.pro",
    info: "Triplique o engajamento de seus leads com mensagens criadas por IA que se alinham com o perfil deles.",
    categories: ["Alcance no LinkedIn", "Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Cold Pitch",
    website: "https://coldpitch.email",
    info: "Personalize seus cold mails em segundos.",
    categories: ["Assistente de Email com IA"],
    aiTools: true
  },
  {
    name: "Artisan",
    website: "https://artisan.co",
    info: "Artisans são trabalhadores digitais de IA prontos para uso que se integram perfeitamente com equipes humanas.",
    categories: ["SDR com IA"],
    aiTools: true
  },
  {
    name: "Octolane",
    website: "https://octolane.com",
    info: "Estamos construindo o primeiro CRM de vendas que pode identificar empresas e seus tomadores de decisão com alta intenção de compra visitando seu site ou as ofertas de soluções de seus concorrentes.",
    categories: ["Banco de Dados B2B", "Rastreamento de Visitantes do Site"],
    aiTools: true
  },
  {
    name: "Hyperbound",
    website: "https://hyperbound.ai",
    info: "Adapte emails de vendas automatizados com IA.",
    categories: ["Treinamento de Vendas"],
    aiTools: true
  },
  {
    name: "Leadbay",
    website: "https://leadbay.ai",
    info: "Treine o Leadbay no conhecimento da sua empresa, gere leads qualificados, preveja o crescimento da receita.",
    categories: ["Inteligência de Receita"],
    aiTools: true
  },
  {
    name: "Toma",
    website: "https://toma.so",
    info: "Toma leva menos de uma hora para configurar, integrar com suas ferramentas, atender todas as suas chamadas de clientes, vender seu serviço e responder perguntas 24/7.",
    categories: ["Integrações e Fluxos de Trabalho"],
    aiTools: true
  },
  {
    name: "Pitch Avatar",
    website: "https://roi4presenter.com/pitch-avatar/",
    info: "Libere o poder do conteúdo personalizado e simplifique a entrega da sua apresentação.",
    categories: ["Apresentações"],
    aiTools: true
  },
  {
    name: "Empler",
    website: "https://empler.ai",
    info: "Experimente sistemas de IA prontos para uso que ajudam funcionários e empregadores a completar tarefas, produzir trabalho da mais alta qualidade e economizar tempo.",
    categories: ["Assistente com IA", "Integrações e Fluxos de Trabalho", "Produtividade"],
    aiTools: true
  },
  {
    name: "Voicestars",
    website: "https://voicestars.ai",
    info: "Transforme sua voz em algo incrível com IA.",
    categories: ["Inteligência de Conversação"],
    aiTools: true
  },
  {
    name: "The Multiverse AI",
    website: "https://themultiverse.ai",
    info: "Transforme suas selfies em fotos profissionais de qualidade de estúdio com IA.",
    categories: ["Criação de imagens"],
    aiTools: true
  },
  {
    name: "Coconaut",
    website: "https://coconaut.io",
    info: "Crie um chatbot como o ChatGPT alimentado pelos seus dados.",
    categories: ["Chatbot"],
    aiTools: true
  },
];

export default toolsData;