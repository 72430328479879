import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import theme from './theme';
import Home from './pages/Home';
import Tools from './pages/Tools';
import About from './pages/About';
import Signup from './pages/Signup';
import Unsubscribe from './components/Unsubscribe';
import toolsData from './toolsData';
import './index.css';

function GoogleAnalytics() {
  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-DKXVN9Q2TR"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-DKXVN9Q2TR');
        `}
      </script>
    </Helmet>
  );
}

function PageTracker() {
  const location = useLocation();
  const [isGtagReady, setIsGtagReady] = useState(false);

  useEffect(() => {
    if (window.gtag) {
      setIsGtagReady(true);
    } else {
      const checkGtagInterval = setInterval(() => {
        if (window.gtag) {
          setIsGtagReady(true);
          clearInterval(checkGtagInterval);
        }
      }, 500);

      return () => clearInterval(checkGtagInterval);
    }
  }, []);

  useEffect(() => {
    if (isGtagReady) {
      window.gtag('config', 'G-DKXVN9Q2TR', { page_path: location.pathname });
    }
  }, [isGtagReady, location]);

  return null;
}

function App() {
  const categories = [...new Set(toolsData.flatMap(tool => tool.categories))];

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <GoogleAnalytics />
        <PageTracker />
        <Box minHeight="100vh" display="flex" flexDirection="column">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route 
              path="/ferramentas" 
              element={<Tools toolsData={toolsData} categories={categories} />} 
            />
            <Route path="/sobre" element={<About />} />
            <Route path="/cadastro" element={<Signup />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;