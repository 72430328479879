import SliderAnimation from '../components/SliderAnimation';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  SimpleGrid,
  Image,
} from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const Homepage = () => {
  return (
    <>
      <SEO 
        title="AI Marketing Apps | Descubra as Melhores Ferramentas de IA para Marketing"
        description="Explore nossa curadoria dos melhores aplicativos de IA para impulsionar suas estratégias de marketing. Aumente sua produtividade e resultados com as ferramentas certas."
        keywords="IA marketing, aplicativos de IA, ferramentas de marketing, automação de marketing, inteligência artificial"
        canonicalUrl="https://aimktapps.com/"
      />
      {/* Resto do conteúdo da página Home */}
    </>
  );
};

const Hero = () => (
  <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between" py={20}>
    <Box flex={1} pr={{ base: 0, md: 8 }}>
      <Heading 
        as="h1" 
        fontFamily="Playfair Display, serif" 
        fontSize="64px" 
        lineHeight="1.2"
        mb={4}
      >
        Descubra os principais aplicativos de IA para Marketing
      </Heading>
      <Text 
        fontSize="xl" 
        mb={6} 
        fontFamily="Lato, sans-serif"
      >
        Explore a lista dos melhores apps de IA para impulsionar sua estratégia de marketing.
      </Text>
      <Button 
        as={RouterLink} 
        to="/ferramentas" 
        bg="#4BB5FF" 
        color="white" 
        size="lg"
        fontFamily="Lato, sans-serif"
      >
        Comece agora!
      </Button>
    </Box>
    <Box flex={1} height="400px" position="relative">
      <SliderAnimation />
    </Box>
  </Flex>
);

const topToolsData = [
  {
    title: "Um dos melhores e mais antigos AIs para copywriting!",
    description: "Completo, atual e em Pt-br",
    link: "https://rytr.me/",
    imageUrl: "/images/app010824.jpg"
  },
  {
    title: "A IA do Notion tem resposta para tudo",
    description: "Conhecimento, respostas, ideias. A um clique de distância.",
    link: "https://www.notion.so/pt-br",
    imageUrl: "/images/app020824.jpg"
  },
  {
    title: "Gere imagens realistas, poster, logótipos e muito mais gratuitamente.",
    description: "Simples e extremamente viciante!",
    link: "https://ideogram.ai/l",
    imageUrl: "/images/app030824.jpg"
  }
];

const ToolCard = ({ title, description, link, imageUrl }) => (
  <Box borderWidth={1} borderRadius="lg" overflow="hidden" bg="white">
    <ChakraLink href={link} isExternal>
      <Image
        src={imageUrl}
        alt={title}
        height="200px"
        width="100%"
        objectFit="cover"
        fallbackSrc="/images/placeholder-tool.jpg"
        transition="transform 0.3s ease-in-out"
        _hover={{ transform: 'scale(1.05)' }}
      />
    </ChakraLink>
    <Box p={4}>
      <Heading as="h3" size="md" mb={2} fontFamily="Playfair Display, serif" fontSize="24px" color="#333">
        {title}
      </Heading>
      <Text mb={4} fontFamily="Lato, sans-serif" fontSize="16px" color="#333">
        {description}
      </Text>
      <Button 
        as="a" 
        href={link} 
        target="_blank" 
        rel="noopener noreferrer" 
        variant="link" 
        color="#4BB5FF"
        fontFamily="Lato, sans-serif"
      >
        Saiba mais
      </Button>
    </Box>
  </Box>
);

const TopTools = () => (
  <Box py={16} bg="#CFDEF4">
    <Container maxW="container.xl">
      <Heading as="h2" size="xl" mb={4} fontFamily="Playfair Display, serif" fontSize="48px" color="#333">
        Top AI Marketing Apps
      </Heading>
      <Text mb={8} fontFamily="Lato, sans-serif" fontSize="18px" color="#333">
        Curadoria do AI MKT Apps
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
        {topToolsData.map((tool, index) => (
          <ToolCard key={index} {...tool} />
        ))}
      </SimpleGrid>
      <Box textAlign="center" mt={8}>
        <Button as={RouterLink} to="/ferramentas" bg="#4BB5FF" color="white" size="lg" fontFamily="Lato, sans-serif">
          Mais Apps
        </Button>
      </Box>
    </Container>
  </Box>
);

const Vision = () => (
  <Box bg="#FF68A5" py={24}>
    <Container maxW="container.xl" textAlign="center">
      <Heading 
        as="h2" 
        mb={4} 
        fontFamily="Playfair Display, serif" 
        fontSize="58px" 
        color="white"
      >
        Nossa missão
      </Heading>
      <Text 
        fontSize="24px" 
        fontFamily="Lato, sans-serif" 
        color="white" 
        maxW="800px" 
        mx="auto"
      >
        Selecionar e recomendar aos profissionais de marketing os melhores aplicativos de IA indispensáveis para marketing e áreas relacionadas, garantindo soluções inovadoras e eficazes para otimizar suas estratégias.
      </Text>
    </Container>
  </Box>
);

const HowItWorks = () => (
  <Box py={16} bg="#FFEAEA">
    <Container maxW="container.xl">
      <Heading 
        as="h2" 
        size="xl" 
        mb={8} 
        fontFamily="Playfair Display, serif"
        fontSize="48px"
        color="#333"
      >
        Como funciona
      </Heading>
      <Text 
        mb={8} 
        fontFamily="Lato, sans-serif"
        fontSize="18px"
        color="#333"
      >
        Aproveite os Melhores Aplicativos de IA para Turbinar Seu Marketing.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
        {[
          { step: 'Selecione', image: '/images/1.jpg' },
          { step: 'Customize', image: '/images/2.jpg' },
          { step: 'Integre', image: '/images/3.jpg' }
        ].map((item, index) => (
          <Box key={index}>
            <Image
              src={item.image}
              alt={`Step ${index + 1}: ${item.step}`}
              height="200px"
              width="100%"
              objectFit="cover"
              mb={4}
              fallbackSrc="/images/placeholder-step.jpg"
            />
            <Heading 
              as="h3" 
              size="md" 
              mb={2}
              fontFamily="Playfair Display, serif"
              fontSize="24px"
              color="#333"
            >
              {item.step}
            </Heading>
            <Text fontFamily="Lato, sans-serif" fontSize="16px" color="#333">
              {index === 0 ? (
                <>
                  <ChakraLink as={RouterLink} to="/ferramentas" color="#FF68A5" fontWeight="bold">
                    Acesse nossa lista curada com os principais aplicativos de IA para marketing.
                  </ChakraLink>
                  
                </>
              ) : index === 1 ? (
                "Use os filtros para definir os melhores apps conforme sua estratégia de marketing."
              ) : (
                "Integre os aplicativos selecionados em suas campanhas para otimizar resultados."
              )}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  </Box>
);

const Newsletter = () => (
  <Box bg="#FF68A5" color="white" py={16} borderRadius="lg">
    <Container maxW="container.md" textAlign="center">
      <Heading as="h2" size="xl" mb={4} fontFamily="Playfair Display, serif">
        Newsletter
      </Heading>
      <Text fontSize="lg" mb={6} fontFamily="Lato, sans-serif">
        Mantenha-se informado sobre as últimas tendências<br />
        e aplicativos de IA para marketing em seu e-mail.
      </Text>
      <Button 
        as={RouterLink} 
        to="/cadastro" 
        bg="white" 
        color="#FF68A5" 
        size="lg"
        fontFamily="Lato, sans-serif"
      >
        Inscreva-se
      </Button>
    </Container>
  </Box>
);

const Home = () => {
  return (
    <Box bg="#CFE5F4" minHeight="100vh">
      <Header />
      <Box bg="#CFE5F4">
        <Container maxW="container.xl">
          <Hero />
        </Container>
      </Box>
      <TopTools />
      <Vision />
      <HowItWorks />
      <Container maxW="container.xl" py={16}>
        <Newsletter />
      </Container>
      <Footer />
    </Box>
  );
};

export default Home;