import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      menuBg: "#CFD7F4",
      headerBg: "#CFE5F4",
      aiToolsBg: "#CFDEF4",
      visionBg: "#FF68A5",
      mainBg: "#FFEAEA",
      ctaBlue: "#4BB5FF",
      cardBg: "#FFEAEA",
    },
  },
  fonts: {
    heading: '"Playfair Display", serif',
    body: '"Lato", sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        solid: {
          bg: 'brand.ctaBlue',
          color: 'white',
          _hover: {
            bg: 'blue.500',
          },
        },
      },
    },
  },
});

export default theme;