import React from 'react';
import { Box, Container, Heading, Text, Button, Flex, Image, VStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const Sobre = () => {
  return (
    <>
      <SEO 
        title="Sobre Nós | AI Marketing Apps - Sua Fonte de Ferramentas de IA para Marketing"
        description="Conheça nossa missão de selecionar e recomendar as melhores ferramentas de IA para profissionais de marketing. Descubra como ajudamos a otimizar estratégias de marketing com soluções inovadoras."
        keywords="sobre nós, missão, IA para marketing, curadoria de apps, inovação em marketing"
        canonicalUrl="https://aimktapps.com/sobre"
      />
      {/* Resto do conteúdo da página About */}
    </>
  );
};

const About = () => {
  return (
    <Box bg="#FF68A5" minHeight="100vh" display="flex" flexDirection="column">
      <Header />
      <Container maxW="container.xl" py={20} flex="1">
        <Flex direction={["column", "column", "row"]} align="center" justify="space-between">
          {/* Imagem à esquerda */}
          <Box width={["100%", "100%", "45%"]} mb={[8, 8, 0]}>
            <Image src="/images/sobrenos.png" alt="Sobre nós" width="100%" />
          </Box>
          
          {/* Conteúdo à direita */}
          <VStack width={["100%", "100%", "50%"]} align="flex-start" spacing={6}>
            <Heading 
              as="h2" 
              fontFamily="Playfair Display" 
              fontWeight="900" 
              fontSize={["40px", "48px", "56px"]} 
              color="#79C8FF"
            >
              Sobre nós
            </Heading>
            <Text 
              fontFamily="Playfair Display" 
              fontWeight="50" 
              fontSize={["18px", "20px", "25px"]} 
              color="#FFF6F6"
              lineHeight="1.4"
            >
              Descubra os melhores apps de IA que irão revolucionar o marketing do seu negócio. Economize tempo, automatize tarefas, supere desafios e destaque-se no mercado digital. Quer receber nossa curadoria de apps, dicas e novidades em seu e-mail? Cadastre-se em nossa newsletter.
            </Text>
            
            {/* Botões centralizados */}
            <Flex justify="center" width="100%" mt={8}>
              <Button 
                as={RouterLink} 
                to="/ferramentas" 
                bg="#4BB5FF" 
                color="white" 
                size="lg" 
                mr={4}
                _hover={{ bg: "#3994D1" }}
              >
                Ver Apps
              </Button>
              <Button 
                as={RouterLink} 
                to="/cadastro" 
                bg="#4BB5FF" 
                color="white" 
                size="lg"
                _hover={{ bg: "#3994D1" }}
              >
                Cadastre-se
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </Container>
      <Footer />
    </Box>
  );
};

export default About;