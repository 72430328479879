import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Heading, Text, Spinner } from '@chakra-ui/react';

const Unsubscribe = () => {
  const [status, setStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
  const unsubscribe = async () => {
    const email = searchParams.get('email');

    if (!email) {
      setStatus('error');
      setErrorMessage('Email não fornecido');
      return;
    }

    try {
      const API_URL = process.env.REACT_APP_API_URL || 'https://one00aimktools-backend.onrender.com';
      const response = await fetch(`${API_URL}/api/unsubscribe?email=${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
        credentials: 'include', // Adicione esta linha
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Falha ao cancelar inscrição');
      }

      const data = await response.json();
      setStatus('success');
    } catch (error) {
      console.error('Erro ao cancelar inscrição:', error);
      setStatus('error');
      setErrorMessage(error.message || 'Erro desconhecido ao cancelar inscrição');
    }
  };

  unsubscribe();
}, [searchParams]);

  if (status === 'loading') {
    return (
      <Box textAlign="center" py={10}>
        <Spinner />
        <Text mt={4}>Processando sua solicitação...</Text>
      </Box>
    );
  }

  if (status === 'success') {
    return (
      <Box textAlign="center" py={10}>
        <Heading as="h1" size="xl" mb={4}>Inscrição Cancelada</Heading>
        <Text>Sua inscrição foi cancelada com sucesso.Lamentamos vê-lo partir!</Text><br />
        <Text mt={4}>Se mudar de ideia, você sempre pode se inscrever novamente em nosso site.</Text>
      </Box>
    );
  }

  return (
    <Box textAlign="center" py={10}>
      <Heading as="h1" size="xl" mb={4}>Erro ao Cancelar Inscrição</Heading>
      <Text>Desculpe, não foi possível processar sua solicitação de cancelamento.</Text>
      <Text mt={4}>{errorMessage}</Text>
      <Text mt={4}>Por favor, tente novamente mais tarde ou entre em contato conosco para assistência.</Text>
    </Box>
  );
};

export default Unsubscribe;