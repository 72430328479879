import React from 'react';
import './SliderAnimation.css';

const SliderAnimation = () => {
  return (
    <div className="banner">
      <div className="slider" style={{"--quantity": 10}}>
        {[...Array(10)].map((_, index) => (
          <div key={index + 1} className="item" style={{"--position": index + 1}}>
            <img src={`/images/dragon_${index + 1}.jpg`} alt={`Dragon ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderAnimation;
