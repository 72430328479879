import React from 'react';
import { Box, Container, Flex, Link, Text } from '@chakra-ui/react';

const Footer = () => (
  <Box as="footer" bg="#CFD7F4" py={8} fontSize="sm">
    <Container maxW="container.xl">
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
        <Text fontFamily="Lato, sans-serif" color="#333">©2024 - AI MKT Apps by Exid. Todos os direitos reservados.</Text>
        <Flex mt={{ base: 4, md: 0 }}>
          <Link href="http://www.exid.com.br" target="_blank" rel="noopener noreferrer" mr={4} fontFamily="Lato, sans-serif" color="#333">
            Powered by Agência Exid
          </Link>
          <Link href="https://www.linkedin.com/groups/14472025/" target="_blank" rel="noopener noreferrer" mr={4} fontFamily="Lato, sans-serif" color="#333">
            | Grupo Linkedin
          </Link>
        </Flex>
      </Flex>
    </Container>
  </Box>
);

export default Footer;
