import React, { useState } from 'react';
import { Box, Button, Container, FormControl, FormLabel, Heading, Input, Select, VStack, useToast, Text, Flex, Image } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { saveSubscriber } from '../utils'; // Certifique-se de que esta função está corretamente implementada

const Signup = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    interest: '',
    frequency: 'weekly',
    optIn: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!formData.name || !formData.email || !formData.interest) {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos obrigatórios.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await saveSubscriber(formData);
      toast({
        title: "Cadastro realizado!",
        description: "Você receberá nossas atualizações em breve.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFormData({
        name: '',
        email: '',
        interest: '',
        frequency: 'weekly',
        optIn: false
      });
    } catch (error) {
      toast({
        title: "Erro no cadastro",
        description: error.message || "Ocorreu um erro ao processar seu cadastro. Por favor, tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box bg="#FFF6F6" minHeight="100vh">
      <Header />
      <Box bg="#CFDEF4" py={12}>
        <Container maxW="container.xl">
          <Heading as="h1" size="2xl" mb={4} fontFamily="Playfair Display" fontWeight="900" fontSize="56px" color="#212121">
            Cadastre-se para receber atualizações
          </Heading>
          <Text fontSize="19px" color="#212121" fontFamily="Source Sans Pro" fontWeight="400">
            Fique por dentro das últimas novidades em IA para marketing
          </Text>
        </Container>
      </Box>
      <Container maxW="container.md" py={12}>
        <Box bg="#FF68A5" p={8} borderRadius="lg" position="relative">
          <Image 
            src="/images/Newsletter.jpg" // Substitua pelo caminho correto da imagem
            alt="Newsletter image"
            position="absolute"
            top="-50px"
            left="50%"
            transform="translateX(-50%)"
            borderRadius="full"
            boxSize="100px"
          />
          <VStack as="form" onSubmit={handleSubmit} spacing={6} align="stretch" mt={12}>
            <Heading as="h2" size="xl" color="white" textAlign="center" mb={4}>
              Newsletter AI MKT APP
            </Heading>
            <FormControl isRequired>
              <FormLabel color="white">Nome</FormLabel>
              <Input 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                bg="white"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color="white">E-mail</FormLabel>
              <Input 
                name="email" 
                type="email" 
                value={formData.email} 
                onChange={handleChange} 
                bg="white"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color="white">Interesse</FormLabel>
              <Select 
                name="interest" 
                value={formData.interest} 
                onChange={handleChange}
                placeholder="Selecione uma opção"
                bg="white"
              >
                <option value="marketing">Marketing</option>
                <option value="vendas">Vendas</option>
                <option value="tecnologia">Tecnologia</option>
                <option value="gestao">Gestão</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel color="white">Frequência da newsletter</FormLabel>
              <Select 
                name="frequency" 
                value={formData.frequency} 
                onChange={handleChange}
                bg="white"
              >
                <option value="biweekly">Quinzenal</option>
                <option value="monthly">Mensal</option>
              </Select>
            </FormControl>
             <FormControl isRequired>
              <Flex align="center">
                <input
                  type="checkbox"
                  name="optIn"
                  checked={formData.optIn}
                  onChange={handleChange}
                  style={{ marginRight: '8px' }}
                />
                <Text color="white">Concordo em receber dicas de apps, novidades e informações do AI MKT Apps em meu e-mail.</Text>
              </Flex>
            </FormControl>
            <Button 
              type="submit" 
              bg="#4BB5FF" 
              color="white" 
              size="lg" 
              width="100%"
              isLoading={isSubmitting}
              _hover={{ bg: "#3994D1" }}
            >
              Inscreva-se
            </Button>
          </VStack>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Signup;