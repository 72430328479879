import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link, Text } from '@chakra-ui/react';

const Header = () => (
  <Box as="header" bg="#CFD7F4" py={4}>
    <Flex maxW="container.xl" mx="auto" px={4} justifyContent="space-between" alignItems="center">
      <Text 
        fontWeight="bold" 
        as={RouterLink} 
        to="/" 
        fontFamily="'Source Sans 3', sans-serif"
        fontSize="22px"
        color="#333"
      >
        AI MKT Apps
      </Text>
      <Flex as="nav">
        <Link as={RouterLink} to="/ferramentas" mr={4} fontFamily="'Source Sans 3', sans-serif" color="#333">Apps</Link>
        <Link as={RouterLink} to="/sobre" mr={4} fontFamily="'Source Sans 3', sans-serif" color="#333">Sobre</Link>
        <Link as={RouterLink} to="/cadastro" fontFamily="'Source Sans 3', sans-serif" color="#333">Newsletter</Link>
      </Flex>
    </Flex>
  </Box>
);

export default Header;